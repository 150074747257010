<template>
    <div class="ss" :class="[ leftVersion ? (mode ? 'l-modal-style' : 'd-modal-style') : '']">
        <div class="app-modal__in">
            <div
                class="app-modal__header d-flex f-between p-5 m-title-modal"
            >
                <p class="large--title m-0">
                   {{$t('message.users')}}
                </p>
                <div>
                    <crm-store-update-close
                        :permission="$options.name"
                        :button_type="'store'"
                        :loading="loadingButton"
                        @c-submit="submit(true)"
                        @c-close="close()"
                    ></crm-store-update-close>
                </div>
            </div>
        </div>
        <!-- app-modal__header end -->

        <div class="person-project pt-2">
            <div style="margin-top: 0px;" class="p-2">
                <el-input  placeholder="Please input" v-model="input2">
                    <template slot="append" ><i class="el-icon-search"></i></template>
                </el-input>
            </div>
            
            <div class="itme-person-project" v-for="(user, index) in users" :key="index">
                <el-checkbox v-model="user.checked">
                    <img v-if="user.avatar" :src="user.avatar" alt="" class="task_user_give_img">
                    <img v-else src="/img/flag/employee.svg" alt="" class="task_user_give_img">
                    <span class="person-name">{{ user.name }}</span>
                </el-checkbox>
            </div>
        </div>

    </div>
</template>

<script>
import {mapActions, mapGetters} from  'vuex';
import _ from 'lodash';
export default {
	props: ['dialog', 'participant_ids'],
	computed:{
		...mapGetters({
			list: 'users/inventory',
            mode: "MODE"

		}),
        leftVersion () {
        return this.$store.getters.LEFTVERSION
        },
	},
    data() {
        return {
			checked: false,
			loadingButton: false,
			users: [],
			checked_ids: []
        };
    },

    methods: {
		...mapActions({
			// updateInventory: 'users/inventory'
		}),
		submit(){
			let data = this.users.filter(item => item.checked);
			this.$emit('set-filter-users', data);
			this.parent().closeDialog();
		},
		afterOpen(){
			if (this.users && this.users.length === 0) this.users = JSON.parse(JSON.stringify(this.list));
			if (!_.isEmpty(this.participant_ids)) {
				this.users.forEach(user => {
					if (_.includes(this.participant_ids, user.id)) {
							user.checked = true;
					}
				});
			}
		},
		close(){
			this.parent().closeDialog();
			// this.users = [];
			// this.$emit('set-filter-users', []);
		},
		parent(){
			return this.$parent.$parent;
		},
		afterLeave(){
			// this.users = [];
		}
    },
};
</script>
