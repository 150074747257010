import { mapGetters, mapActions } from "vuex";
import _ from 'lodash';
import MultiSelectUsers from '@/components/multiSelects/multi-select-users';
import MultiSelectTypeOfProjectWorks from '@/components/multiSelects/multi-select-type-of-project-works';
import MultiSelectFilials from '@/components/multiSelects/multi-select-filials';
import CrmMoneyInput from '@/components/crm/crm-money-input';
import SelectCurrency from '@/components/selects/select-currency';

export default {
	components:{
		MultiSelectUsers,
		MultiSelectTypeOfProjectWorks,
		MultiSelectFilials,
		CrmMoneyInput,
		SelectCurrency
	},
	computed: {
		...mapGetters({
			model: "projects/model",
			rules: "projects/rules",
			columns: "projects/columns",
			currencies: "currency/inventory"
		}),
		formatDateNow() {
			let date = new Date();
			let year = date.getFullYear().toString();
			let month = (date.getMonth() + 101).toString().substring(1);
			let day = (date.getDate() + 100).toString().substring(1);
			return year + "-" + month + "-" + day;
		},
	},
	data() {
		return {
			centerDialogVisible: false,
			checked: true,
			loadingData: false
		}
	},
	methods: {
		submit(close = true) {
			this.$refs["form"].validate((valid) => {
				if (valid) {
					this.loadingButton = true;
					this.save(this.form)
						.then((res) => {
							this.loadingButton = false;
							this.$alert(res);
							this.parent().listChanged();
							if (close) this.close();
						})
						.catch((err) => {
							this.loadingButton = false;
							this.$alert(err);
						});
				}
			});
	   },
	   changeCurrency(currency_id){
			let currency = _.find(this.currencies, ['id', currency_id]);
			if (currency){
				this.form.currency_rate = currency.rate;
			}
	   }
	},

}